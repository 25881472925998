var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "solutions-area section-width ptb-100" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "section-title" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("navbar.menu_1_1")))]),
        _c("p", [
          _vm._v(
            " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. "
          )
        ])
      ]),
      _c("div", { staticClass: "text-left" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-6 mt-4" },
            [
              _c(
                "b-input-group",
                { attrs: { size: "mg", prepend: _vm.$t("selection.title_1") } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.year },
                    on: {
                      change: function($event) {
                        return _vm.onChangeYear()
                      }
                    },
                    model: {
                      value: _vm.selected_year,
                      callback: function($$v) {
                        _vm.selected_year = $$v
                      },
                      expression: "selected_year"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-lg-6 mt-4" },
            [
              _c(
                "b-input-group",
                { attrs: { size: "mg", prepend: _vm.$t("selection.title_2") } },
                [
                  _c("b-form-select", {
                    attrs: { options: _vm.month, disabled: _vm.isYear },
                    on: {
                      change: function($event) {
                        return _vm.onChangeValue()
                      }
                    },
                    model: {
                      value: _vm.selected_month,
                      callback: function($$v) {
                        _vm.selected_month = $$v
                      },
                      expression: "selected_month"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-lg-12 mt-4" },
            [
              _c(
                "b-form-group",
                [
                  _c("b-form-tags", {
                    staticClass: "mb-2",
                    attrs: {
                      id: "tags-component-select",
                      size: "mg",
                      "add-on-change": "",
                      "no-outer-focus": ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(ref) {
                          var tags = ref.tags
                          var inputAttrs = ref.inputAttrs
                          var inputHandlers = ref.inputHandlers
                          var disabled = ref.disabled
                          var removeTag = ref.removeTag
                          return [
                            _c(
                              "b-input-group",
                              {
                                attrs: {
                                  size: "mg",
                                  prepend: _vm.$t("selection.title_3")
                                }
                              },
                              [
                                _c(
                                  "b-form-select",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        attrs: {
                                          disabled:
                                            disabled ||
                                            _vm.availableOptions.length === 0,
                                          options: _vm.availableOptions
                                        },
                                        on: { change: _vm.selectedTagValue },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "first",
                                              fn: function() {
                                                return [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: {
                                                        disabled: "",
                                                        value: ""
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "selection.option_3"
                                                          )
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              },
                                              proxy: true
                                            }
                                          ],
                                          null,
                                          true
                                        )
                                      },
                                      "b-form-select",
                                      inputAttrs,
                                      false
                                    ),
                                    inputHandlers
                                  )
                                )
                              ],
                              1
                            ),
                            tags.length > 0
                              ? _c(
                                  "ul",
                                  {
                                    staticClass:
                                      "list-inline d-inline-block mt-3"
                                  },
                                  _vm._l(tags, function(tag) {
                                    return _c(
                                      "li",
                                      {
                                        key: tag,
                                        staticClass: "list-inline-item"
                                      },
                                      [
                                        _c(
                                          "b-form-tag",
                                          {
                                            attrs: {
                                              title: tag,
                                              disabled: disabled,
                                              variant: "info"
                                            },
                                            on: {
                                              remove: function($event) {
                                                removeTag(tag),
                                                  _vm.removerTagValue(tag)
                                              }
                                            }
                                          },
                                          [_vm._v(_vm._s(tag))]
                                        )
                                      ],
                                      1
                                    )
                                  }),
                                  0
                                )
                              : _vm._e()
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.valueTag,
                      callback: function($$v) {
                        _vm.valueTag = $$v
                      },
                      expression: "valueTag"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "row row-cols-1 row-cols-md-3" },
        _vm._l(_vm.cardList, function(item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "col mb-4 animated fadeInUp",
              on: {
                click: function($event) {
                  return _vm.getExhibitionContent(item.activity_event_id)
                }
              }
            },
            [
              _c("div", { staticClass: "card h-100 act-card-hover" }, [
                _c("img", {
                  staticClass: "card-img-top",
                  attrs: { src: item.path, alt: "Card image cap" }
                }),
                _c("div", { staticClass: "card-body" }, [
                  _c("small", [_vm._v(_vm._s(item.show_time))]),
                  _c("p", { staticClass: "card-text" }, [
                    _vm._v(_vm._s(item.event_title))
                  ])
                ]),
                _c(
                  "div",
                  { staticClass: "card-footer" },
                  [
                    _vm._l(item.tag, function(tagItem, tagIndex) {
                      return _c(
                        "span",
                        { key: tagIndex, staticClass: "badge badge-info" },
                        [_vm._v("# " + _vm._s(tagItem.tag_name))]
                      )
                    }),
                    _c("i", { staticClass: "fas fa-eye" }, [
                      _vm._v(_vm._s(item.clicks))
                    ])
                  ],
                  2
                )
              ])
            ]
          )
        }),
        0
      ),
      _c(
        "div",
        {
          class: [
            "row",
            "text-center",
            { "is-show-next-page": _vm.isShowNextPage }
          ]
        },
        [
          _c("div", { staticClass: "col-lg-12 read-more" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-warning",
                on: {
                  click: function($event) {
                    return _vm.getCardListNextPage()
                  }
                }
              },
              [
                _vm._v(" " + _vm._s(_vm.$t("pageBtn"))),
                _c("i", { staticClass: "fas fa-chevron-right ml-2" })
              ]
            )
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }