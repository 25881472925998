<template>
  <div class="solutions-area section-width ptb-100">
    <div class="container">
      <div class="section-title">
        <h2>{{ $t("navbar.menu_1_1") }}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incidiunt labore et dolore magna aliqua. Quis ipsum
          suspendisse ultrices gravida.
        </p>
      </div>

      <div class="text-left">
        <div class="row">
          <div class="col-lg-6 mt-4">
            <b-input-group size="mg" :prepend="$t('selection.title_1')">
              <b-form-select
                v-model="selected_year"
                :options="year"
                @change="onChangeYear()"
              >
              </b-form-select>
            </b-input-group>
          </div>
          <div class="col-lg-6 mt-4">
            <b-input-group size="mg" :prepend="$t('selection.title_2')">
              <b-form-select
                v-model="selected_month"
                :options="month"
                :disabled="isYear"
                @change="onChangeValue()"
              ></b-form-select>
            </b-input-group>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 mt-4">
            <b-form-group>
              <b-form-tags
                id="tags-component-select"
                v-model="valueTag"
                size="mg"
                class="mb-2"
                add-on-change
                no-outer-focus
              >
                <template
                  v-slot="{
                    tags,
                    inputAttrs,
                    inputHandlers,
                    disabled,
                    removeTag,
                  }"
                >
                  <b-input-group size="mg" :prepend="$t('selection.title_3')">
                    <b-form-select
                      v-bind="inputAttrs"
                      v-on="inputHandlers"
                      :disabled="disabled || availableOptions.length === 0"
                      :options="availableOptions"
                      @change="selectedTagValue"
                    >
                      <template #first>
                        <option disabled value="">{{
                          $t("selection.option_3")
                        }}</option>
                      </template>
                    </b-form-select>
                  </b-input-group>
                  <ul
                    v-if="tags.length > 0"
                    class="list-inline d-inline-block mt-3"
                  >
                    <li v-for="tag in tags" :key="tag" class="list-inline-item">
                      <b-form-tag
                        @remove="removeTag(tag), removerTagValue(tag)"
                        :title="tag"
                        :disabled="disabled"
                        variant="info"
                        >{{ tag }}</b-form-tag
                      >
                    </li>
                  </ul>
                </template>
              </b-form-tags>
            </b-form-group>
          </div>
        </div>
      </div>
      <!-- <section class="blog-area pb-70"> -->
      <div class="row row-cols-1 row-cols-md-3">
        <div
          class="col mb-4 animated fadeInUp"
          v-for="(item, index) in cardList"
          :key="index"
          @click="getExhibitionContent(item.activity_event_id)"
        >
          <div class="card h-100 act-card-hover">
            <img class="card-img-top" :src="item.path" alt="Card image cap" />
            <div class="card-body">
              <small>{{ item.show_time }}</small>
              <p class="card-text">{{ item.event_title }}</p>
            </div>
            <div class="card-footer">
              <span
                v-for="(tagItem, tagIndex) in item.tag"
                :key="tagIndex"
                class="badge badge-info"
                ># {{ tagItem.tag_name }}</span
              >
              <i class="fas fa-eye">{{ item.clicks }}</i>
            </div>
          </div>
        </div>
      </div>

      <div
        :class="['row', 'text-center', { 'is-show-next-page': isShowNextPage }]"
      >
        <div class="col-lg-12 read-more">
          <button class="btn btn-warning" @click="getCardListNextPage()">
            {{ $t("pageBtn") }}<i class="fas fa-chevron-right ml-2"></i>
          </button>
        </div>
      </div>
      <!-- </section> -->
    </div>
  </div>
</template>

<script>
import DateFormat from "dateformat";

export default {
  name: "ExhibitionBody",
  data() {
    return {
      selected_year: null,
      selected_month: null,
      year: [
        {
          value: null,
          text: this.$t("selection.option_1"),
        },
      ],
      month: [
        {
          value: null,
          text: this.$t("selection.option_2"),
        },
      ],
      classArr: ["apple", "orange", "grape"],
      dateData: [],
      isYear: true,
      optionsTag: [],
      valueTag: [],
      cardList: [],
      apiUrl: `${process.env.VUE_APP_API_URL}/activity/`,
      page: 1,
      isShowNextPage: false,
    };
  },

  computed: {
    availableOptions() {
      return this.optionsTag.filter((opt) => this.valueTag.indexOf(opt) === -1);
    },
  },
  async mounted() {
    await this.getDate();
    await this.getExhibitionList();
  },
  methods: {
    async getDate() {
      let api = `${this.apiUrl}/event_time_list?activity_id=1`;
      await this.axios.get(api).then((response) => {
        this.dateData = response["data"];
        this.year = [
          {
            value: null,
            text: this.$t("selection.option_1"),
          },
        ];
        for (let arr in response["data"]) {
          this.year.push({
            value: arr,
            text: arr,
          });
        }
      });
      //init
      //this.selected_year = new Date().getFullYear();
      //this.onChangeYear();
      //this.selected_month = this.month[0].value
      this.getTag();
    },
    async getTag() {
      let lang = this.$i18n.locale;
      let api = `${this.apiUrl}/tag_list?activity_id=1&group=${lang}`;
      await this.axios.get(api).then((response) => {
        let data = response["data"]["list"];
        for (let i = 0; i < data.length; i++) {
          this.optionsTag.push(data[i]["tag_name"]);
          // this.valueTag.push(data[i]['tag_name']);
        }
      });
    },
    getExhibitionList() {
      let api = `${this.apiUrl}event_list`;
      let postParams = {
        activity_id: 1,
        page: this.page,
        limit: "9",
        language_id: this.$i18n.locale,
        order_by: [
          {
            field: "start_time",
            method: "ASC",
          },
        ],
        filters: {
          tags: [],
          time_range: {
            start: DateFormat(new Date(), "yyyy-mm-dd"),
          },
        },
        image_width: 350,
      };
      if (this.valueTag.length != 0) {
        postParams.filters.tags.push(this.valueTag);
      }
      if (this.selected_year == null && this.selected_month == null) {
        postParams.filters.time_range.start = DateFormat(
          new Date(),
          "yyyy-mm-dd"
        );
        delete postParams.filters.time_range.end;
      }
      if (this.selected_year != null && this.selected_month == null) {
        postParams.filters.time_range.start = `${this.selected_year}-01-01`;
        postParams.filters.time_range.end = `${this.selected_year}-12-31`;
      }

      if (this.selected_year != null && this.selected_month != null) {
        let lastDay = new Date(this.selected_year, this.selected_month, 0)
          .toString()
          .split(" ")[2];
        postParams.filters.time_range.start = `${this.selected_year}-${this.selected_month}-01`;
        postParams.filters.time_range.end = `${this.selected_year}-${this.selected_month}-${lastDay}`;
      }
      let postConfig = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      this.axios.post(api, postParams, postConfig).then((response) => {
        let pageNumber = response["data"]["count"]["all"];
        this.showPageBtnCheck(pageNumber);
        let data = response["data"]["list"];
        for (let i = 0; i < data.length; i++) {
          let tagList = data[i]["tag"];
          let tags = [];
          //處理 Tag lang
          for (let tagIndex in tagList) {
            if (tagList[tagIndex].tag_group == this.$i18n.locale) {
              tags.push(tagList[tagIndex]);
            }
          }
          data[i]["tag"] = tags;

          //處理時間格式
          data[i]["start_time"] = DateFormat(
            new Date(Date.parse(data[i]["start_time"].replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );
          data[i]["end_time"] = DateFormat(
            new Date(Date.parse(data[i]["end_time"].replace(/-/g, "/"))),
            "yyyy-mm-dd"
          );

          if (data[i]["start_time"] == data[i]["end_time"]) {
            data[i]["show_time"] = data[i]["end_time"];
          } else {
            data[i]["show_time"] =
              data[i]["start_time"] + "~" + data[i]["end_time"];
          }
          data[i]["event_title"] = data[i]["event_title"][this.$i18n.apiLang];
        }

        if (this.page == 1) {
          this.cardList = data;
        } else {
          const cardListStash = this.cardList.concat(data);
          this.cardList = cardListStash;
        }
      });
    },
    onChangeYear() {
      this.isYear = false;
      this.page = 1;
      this.month = [
        {
          value: null,
          text: this.$t("total"),
        },
      ];
      if (this.selected_year != null) {
        const data = this.dateData[this.selected_year];
        for (let i = 0; i < data.length; i++) {
          data[i] = data[i].toString().length == 2 ? data[i] : "0" + data[i];
          this.month.push({
            value: data[i],
            text: data[i],
          });
        }
      }
      this.selected_month = null;
      this.getExhibitionList();
    },
    onChangeValue() {
      this.page = 1;
      this.getExhibitionList();
    },
    selectedTagValue(val) {
      this.valueTag.push(val);
      this.page = 1;
      this.getExhibitionList();
    },
    removerTagValue(tag = "") {
      let valueTagStash = this.valueTag.filter((item) => {
        return item !== tag;
      });
      this.valueTag = valueTagStash;
      this.page = 1;
      this.getExhibitionList();
    },
    getExhibitionContent(activity_event_id) {
      this.$router.push(`/visitors-exhibition/event/${activity_event_id}`);
    },
    getCardListNextPage() {
      this.page += 1;
      this.getExhibitionList();
    },
    showPageBtnCheck(pageNumber) {
      this.isShowNextPage = false;
      if (parseInt(pageNumber) / 9 <= this.page) {
        this.isShowNextPage = true;
      }
    },
  },
};
</script>

<style scoped>
.is-show-next-page {
  display: none;
}

.badge {
  font-weight: 500;
}

.fa-eye {
  padding-top: 5px;
  float: right;
}

.read-more {
  border-top: 1px solid #ced4da;
  padding-top: 25px;
}

.fa-eye::before {
  margin-right: 5px;
}

.card-footer {
  color: #b5bac1;
  background-color: #fff;
}

.card img {
  object-fit: cover;
  height: 225px;
}

.solutions-area.section-width .container {
  max-width: 1000px;
}

.list-inline-item span {
  margin-bottom: 0;
  padding: 6px 10px 8px 10px;
  font-size: 14px;
}

.form-control {
  border: 0px;
  padding: 0;
  background-color: transparent;
}

.b-form-tag > button.b-form-tag-remove {
  color: #fff;
}

.b-form-tag-content {
  color: #ffffff !important;
}

.cover-image {
  margin-bottom: 50px;
  width: 100%;
  height: 400px;
}

.in-btn {
  margin-left: 30px;
  padding-left: 30px;
  padding-right: 30px;
}

.badge-info {
  padding: 5px 8px;
  margin-right: 4px;
  font-size: 14px;
}

.card-body {
  padding: 10px 20px;
}

.card-body small {
  color: #b5bac1;
  font-family: Noto Sans, Apple Casual, Corbel;
  font-size: 16px;
}

.card-body .card-text {
  font-weight: bold;
  font-size: 18px;
}

.custom-select:focus {
  box-shadow: none !important;
  border: 1px solid #ced4da;
}

.act-card-hover {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 10%);
  cursor: pointer;
  transition: 0.5s;
}

.act-card-hover:hover {
  transform: scale(1.02, 1.02);
}
</style>
